import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/img/LOGO EAR 2024-B.png'
import { AppContexte } from '../../context/context'
import Cookies from 'js-cookie'

const Sidebar = () => {

    const { setSection, setLog } = useContext(AppContexte)

    const links =[
        {name: " accueil", icon:"ri-home-6-line", path:"/"},
        {name: " nouveau", icon:"ri-file-add-line", path:"/add"},
        {name: " voir tout", icon:"ri-eye-line", path:"/show"},
        {name: " mon compte", icon:"ri-account-box-line", path:"/account"},
    ]

    const [max, setMax] = useState(true)

    const style ={
        width : "100px"
    }

    const handleLogout = () =>{
        Cookies.remove('log')
        setLog(false)
    }

    return (
        <div className='d-flex position-relative'>
            <div className='sidebar d-none d-md-flex' style={!max?style:null}>
                <div className='sidebar-container'>
                    <div className='hide-arrow' onClick={()=>setMax(!max)}>
                        <button>
                            <span className='w-100 d-flex justify-content-center align-items-center'><i className={'d-flex fa fa-angle-left'  + (max ? '' : ' fa-rotate-180')}></i></span>
                        </button>
                    </div>
                    <div className='sidebar-menu'>
                        <div className={'logo-brand '  + (max ? '' : 'ms-3')}>
                            <div className='d-flex'>
                                <div className='d-inline-dlex flex-wrap'>
                                    <img src={logo} alt='logo EAR rallye GT' className={'img-fluid ' + (max ? 'w-50' : 'w-75')} />
                                    {max? <span className='ms-3'>EAR news</span> :null}
                                </div>
                            </div>
                        </div>
                        <div className='menu'>
                            {
                                links.map((elt,index) =>{
                                    return(
                                        <div key={index} onClick={()=>setSection(elt.name)} className={'my-3 ' + (max ? 'me-3' : 'w-1')}>
                                            <NavLink to={elt.path} activeclassname='active' className={'ps-4 nav-link py-0 btn d-flex align-items-center rounded-start-0 rounded-end-pill ' + (max?'pe-5':'pe-0')}>
                                                <i className={ elt.icon + ' ri-2x opacity-75 '}></i>
                                                {max ? <span className='text-capitalize'> {elt.name} </span>: null}
                                            </NavLink>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='log-out'>
                        <div onClick={handleLogout} className='d-flex flex-row text-dark'>
                            <div className='my-3'>
                                <button activeclassname='active' className='ps-4 pe-5 nav-link py-0 btn d-flex align-items-center rounded-start-0 rounded-end-pill'>
                                    <i className={'ri-logout-box-r-line ri-2x pe-4 opacity-75'}></i>
                                    {max ?<span className='pe-3 text-capitalize'> Se déconnecter </span>:null}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" id="submenu">
                <div className="offcanvas-header">
                    <div className={'logo-brand ms-3'}>
                        <div className='d-flex'>
                            <div className='d-inline-dlex flex-wrap'>
                                <img src={logo} alt='logo EAR rallye GT' className={'img-fluid w-50'} />
                            </div>
                        </div>
                    </div>
                    <button data-bs-dismiss="offcanvas" className='btn border-0'><i className="ri-close-line ri-2x"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className='menu'>
                        {
                            links.map((elt,index) =>{
                                return(
                                    <div key={index} onClick={()=>setSection(elt.name)} data-bs-toggle="offcanvas" data-bs-target="#submenu" className={'my-3 ' + (max ? 'me-3' : 'w-1')}>
                                        <NavLink to={elt.path}  activeclassname='active' className={'ps-4 nav-link py-0 btn d-flex align-items-center rounded-start-0 rounded-end-pill ' + (max?'pe-5':'pe-0')}>
                                            <i className={ elt.icon + ' ri-2x opacity-75 '}></i>
                                            {max ? <span className='text-capitalize'> {elt.name} </span>: null}
                                        </NavLink>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className=''>
                    <div className='log-out'>
                        <div onClick={handleLogout} className='d-flex flex-row text-dark'>
                            <div className='my-3'>
                                <button activeclassname='active' className='ps-4 pe-5 nav-link py-0 btn d-flex align-items-center rounded-start-0 rounded-end-pill'>
                                    <i className={'ri-logout-box-r-line ri-2x pe-4 opacity-75'}></i>
                                    {max ?<span className='pe-3 text-capitalize'> Se déconnecter </span>:null}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
