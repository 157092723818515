import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { URL_API_ACTU, URL_API_CATEGORIE, URL_API_USER } from '../constant/url'
import Cookies from 'js-cookie'

export const AppContexte = React.createContext()


export const GlobalApp  = ({children}) =>{

    const[user, setUser]= useState([])

    const[section, setSection]= useState('WELCOME')

    const[categorie, setCategorie]= useState([])
    const[actu, setActu]= useState([])
    const[part, setPart] = useState('')
    const[title, setTitle] = useState('')
    const[subtitle, setSubtitle] = useState('')
    const[text, setText] = useState('')
    const[image, setImage] = useState()

    //Logging state
    const [log, setLog] = useState()

    //Loading error
    const [loadingErr, setLoadingErr] = useState(false)

    // Loading
    const [load, setLoad] = useState(false)


    useEffect(()=>{
        if(Cookies.get('log')){
            setLog(Cookies.get('log'))
        }else{
            setLog(false)
        }
        axios
        .get(URL_API_CATEGORIE)
        .then((response)=>{
            setCategorie(response.data)
            axios
            .get(URL_API_ACTU)
            .then((response)=>{
                setActu(response.data)
                axios
                .get(URL_API_USER)
                .then((response)=>{
                    setUser(response.data)
                    setTimeout(()=>{
                        setLoad(true)
                    },2000)
                })
                .catch((err) => {console.log(err.message);setLoad(true); setLoadingErr(true)})
            })
            .catch((err) => {console.log(err.message);setLoad(true); setLoadingErr(true)})
        })
        .catch((err) => {console.log(err.message);setLoad(true); setLoadingErr(true)})
    },[])


    let update = {
        categorie : categorie,
        setCategorie : setCategorie,
        actu : actu,
        setActu : setActu,
        part : part,
        setPart : setPart,
        title : title,
        setTitle : setTitle,
        subtitle : subtitle,
        setSubtitle : setSubtitle,
        text : text,
        setText : setText,
        image : image,
        setImage : setImage,
        section: section,
        setSection: setSection,

        //loading error
        loadingErr: loadingErr,
        setLoadingErr: setLoadingErr,

        //loading
        load: load,
        setLoad: setLoad,
        
        //login state
        log: log,
        setLog: setLog,

        user: user,
        setUser: setUser,
    }

    return(
        <AppContexte.Provider value={update}>
            {children}
        </AppContexte.Provider>
    )
}