/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react'
import { AppContexte } from '../context/context'
import Modal from 'react-responsive-modal'
import { Accordion } from 'react-bootstrap'
import axios from 'axios'
import { URL_API, URL_API_ACTU, URL_API_CATEGORIE } from '../constant/url'
import { useLocation } from 'react-router-dom'
import SubmitModal from '../components/ui/SubmitModal'
import { ClipLoader } from 'react-spinners'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'

const Show = () => {

    const {register,formState: { errors }} = useForm();

    const [read, setRead] = useState(false)
    const [error, setError] = useState(false)
    const [ok, setOk] = useState(false)

    const {actu, setActu, categorie, setCategorie} = useContext(AppContexte)
    useEffect(()=>{
        axios
        .get(URL_API_ACTU)
        .then((response)=>{setActu(response.data)})
    },[setActu])


    const[ showT, setShowT] = useState('')
    const[ showS, setShowS] = useState('')
    const[ showI, setShowI] = useState('')
    const[ showIp, setShowIp] = useState('')
    const[ showP, setShowP] = useState('')
    const[ id, setId] = useState('')
    const[ part, setPart] = useState('')
    const[ see, setSee] = useState(false)
    const[ del, setDel] = useState(false)
    const[ update, setUpdate] = useState(false)

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start", inline: "start" });
            }
        }, 0);
        }
    }, [pathname, hash, key]); 


    // Editor toolbor
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "code-block"
    ];

    const modules ={
        toolbar: [
            [{ 'font': [] }],
            [{size: []}],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            [{ list:  "ordered" }, { list:  "bullet" }, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'align': [] }],
            ["link"],
            ['clean']   
            ]
    }

    const bestCat = (idCat, idActu) => {
        axios
        .put(`${URL_API_CATEGORIE}${idCat}`, {best: idActu})
        .then(res => {
            axios.get(URL_API_CATEGORIE).then((response)=>{setCategorie(response.data)})
        }).catch((error) => {
            console.log(error.message)
        })
    }


    const deleteActu = ()=>{
        setRead(true)
        axios.delete(`${URL_API_ACTU}${id}`)
        .then(res => {
            setRead(false)
            setOk(true)
            setTimeout(()=>{
                setDel(false)
            },3000)
            setId('')
            axios.get(URL_API_ACTU).then((response)=>{setActu(response.data)})
        }).catch((error) => {
            console.log(error.message)
            setRead(false)
            setError(true)
        })
    }

    const updateActu = async(e) =>{
        setRead(true)
        const formData = new FormData()
        formData.append('image', showIp)
        formData.append('title', showT)
        formData.append('subtitle', showS)
        formData.append('text', showP)
        formData.append('categorie_id', part)

        axios.put(`${URL_API_ACTU}${id}`, formData)
        .then(res => {
            setRead(false)
            setOk(true)
            setTimeout(()=>{
                setUpdate(false)
            },3000)
            setId('')
            axios.get(URL_API_ACTU).then((response)=>{setActu(response.data)})
        }).catch((error) => {
            console.log(error.message)
            setRead(false)
            setError(true)
        })
    }




    return (
        <div className=''>
            <div className='text-center'>
                <h1 className='mb-4'>LISTE DE TOUTES LES ACTUALITES DE EAR</h1>
            </div>
            <div className='row justify-content-start align-items-center mx-3'>
                {
                    categorie.map((cat, index) =>{
                        return(
                            <div key={index} className='my-5'>
                                <h4 className='text-capitalize'> {cat.label} </h4>
                                {   actu.length ?
                                    actu.map((elt,index)=>(
                                        elt.categorie_id === cat.id ?
                                            <div className='col-12 position-relative d-flex justify-content-start align-items-center bg-white rounded-4 border py-1 ps-1 my-1' key={index} id={elt.id} >
                                                <div className='img-show  d-flex justify-content-center align-items-center overflow-hidden rounded-start-3 pe-2'>
                                                    <img src={`${URL_API}/${elt.image}`} alt={elt.title + " " + elt.subtitle} className='img-fluid' />
                                                </div>
                                                <div className='text-end'>
                                                    <h3 className='text-uppercase text-start title-show m-0'> {elt.title} </h3>
                                                    <h6 className='text-ear text-start title-show fw-light m-0'> {elt.subtitle} </h6>
                                                    <small className='text-end text-dark text-opacity-50'>{(elt.createdAt).substring(0,10) + " " + (elt.createdAt).substring(11,19)}</small>
                                                    <span className='ms-3' role='button' onClick={()=>bestCat(cat.id,elt.id)}>
                                                        {
                                                            read ?
                                                            <button disabled className='d-flex btn bg-transparent'><ClipLoader 
                                                                color='#fbb03b' 
                                                                size={10}
                                                                cssOverride={{display : "flex", margin : "0 auto"}} 
                                                                aria-label='Loading spinner' 
                                                                data-testid='loader' 
                                                                className='text-center ps-3'/>Patientez</button>:
                                                            (
                                                                // eslint-disable-next-line eqeqeq
                                                                elt.id == cat.best ?
                                                                <i className=' ri-star-fill fs-4 text-warning'></i>:
                                                                <i className=' ri-star-line fs-4'></i>
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                                <div className='position-absolute d-flex bg-white p-1 rounded-3 border border-1 end-0 top-0 shadow-sm'>
                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Body>
                                                                <div className='d-flex'>
                                                                    <button onClick={()=>{setShowI(elt.image); setShowT(elt.title); setShowS(elt.subtitle); setShowP(elt.text); setSee(true)}} className='btn me-1'>
                                                                        <i className='ri-eye-line text-primary'></i>
                                                                    </button>
                                                                    <button onClick={()=>{setShowI(elt.image); setShowT(elt.title); setShowS(elt.subtitle); setShowP(elt.text); setId(elt.id); setPart(cat.id); setUpdate(true)}} className='btn me-1'>
                                                                        <i className='ri-edit-box-line text-success'></i>
                                                                    </button>
                                                                    <button onClick={()=>{setShowI(elt.image); setShowT(elt.title); setShowS(elt.subtitle); setShowP(elt.text); setId(elt.id); setDel(true)}} className='btn me-1'>
                                                                        <i className='ri-file-reduce-line text-danger'></i>
                                                                    </button>
                                                                </div>
                                                            </Accordion.Body>
                                                            <Accordion.Header><i className="ri-more-2-line ri-2xl" role='button'></i></Accordion.Header>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        :null
                                    ))
                                :null
                                }
                            </div>
                        )
                    })
                }
            </div>
            <Modal open={see} onClose={()=>{setSee(false);setShowI(''); setShowT(''); setShowS(''); setShowP('');}} center={true} showCloseIcon={true}>
                <div className='container d-flex justify-content-center'>
                    <div className='bg-black container py-3 text-white ql-editor '>
                        <h2 className='text-uppercase text-center'> {showT} </h2>
                        <img src={`${URL_API}${showI}`} className='img-fluid' />
                        <h5 className='text-ear text-start mt-3'> {showS} </h5>
                        <div className='text-justify' dangerouslySetInnerHTML={{ __html: showP}}></div>
                    </div>
                </div>
            </Modal>

            <Modal open={del} onClose={()=>{setDel(false);setShowI(''); setShowT(''); setShowS(''); setShowP('');}} center={true} showCloseIcon={true}>
                <h2 className='text-danger text-center'>VOULEZ VOUS VRAIMENT SUPPRIMER CET ARTICLE ?</h2>
                <div className='container d-flex justify-content-center'>
                    <div className='bg-black container py-3 text-white'>
                        <h2 className='text-uppercase text-center'> {showT} </h2>
                        <img src={`${URL_API}${showI}`} className='img-fluid' />
                        <h5 className='text-ear text-start mt-3'> {showS} </h5>
                        <div className='text-justify' dangerouslySetInnerHTML={{ __html: showP}}></div>
                    </div>
                </div>
                <div className='text-center mt-2'>
                    <h5 className='text-danger'>Cet action est irréversible</h5>
                    {
                        read?<button disabled className='d-flex btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={{display : "flex", margin : "0 auto"}} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center ps-3'/>Patientez</button>:
                        error?<SubmitModal open={error} setOpen={setError} message={"Erreur survenu lors de la suppression"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        ok?<SubmitModal open={ok} setOpen={setOk} message={"Article supprimée"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        <input type='submit' value='Supprimer' onClick={deleteActu} className='form-control bg-danger border-danger text-white fw-bold'/>
                    }
                </div>
            </Modal>
            
            <Modal open={update} onClose={()=>{setUpdate(false);setShowI(''); setShowT(''); setShowS(''); setShowP('');}} center={true} showCloseIcon={true}>
                <h2 className='text-info text-center'>VOUS POUVEZ MODIFIER LES INFOS DE VOTRE ARTICLE</h2>
                
                <form encType='multipart/form-data'>
                    <div className='row justify-content-start align-items-strech'>
                        <div className='col-12 mb-2'>
                            <div className='inputBox w-100'>
                                <select  {...register("part", {required: true})} onChange={(e)=>{setPart(e.target.value);}} value={part} className='w-100'>
                                    <option value={""} defaultValue> --Aucun choix--- </option>
                                    {
                                        categorie.map((elt,index)=>{
                                            return(
                                                <option value={elt.id} key={index} className='text-capitalize' > {elt.label} </option>
                                            )
                                        })
                                    }
                                </select>
                                <span>Sélectionner une catégorie</span>
                                <i></i>
                            </div>
                            {errors.part && <small className="text-danger" role="alert">Catégorie requise</small>}
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='inputBox'>
                                <input {...register("title", {required: true })} onChange={(e)=>(setShowT(e.target.value))} value={showT} type='text' className='w-100'/>
                                <span>Titre</span>
                                <i></i>
                            </div>
                            {errors.title && <small className="text-danger" role="alert">Titre requis</small>}
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='inputBox'>
                                <input {...register("subtitle", {required: false })} onChange={(e)=>(setShowS(e.target.value))} value={showS} type='text' className='w-100'/>
                                <span>Sous titre</span>
                                <i></i>
                            </div>
                            {/* {errors.subtitle && <small className="text-danger" role="alert">Sous titre requis</small>} */}
                        </div>
                        <div className='col-12 mt-2'>
                            <div className='inputFile mb-2'>
                                <label htmlFor='image' className='d-flex flex-column justify-content-center align-items-center h-100'>
                                    <img src={showIp? URL.createObjectURL(showIp):`${URL_API}${showI}`} className='img-fluid' />
                                    <span className='text-opacity-50 text-black text-center fw-medium'>Cliquer pour choisir une image ( Entre 0 - 1Mo )</span>
                                </label>
                                <input {...register("image", {required: false })} id='image' onChange={(e)=>setShowIp(e.target.files[0])} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                            </div>
                            {/* {errors.title && <small className="text-danger" role="alert">Titre requis</small>} */}
                        </div>
                        <div className='col-12 mt-4'>
                            <span className='ms-2'>Contenu</span>
                            <ReactQuill 
                                theme="snow" 
                                value={showP} 
                                onChange={(e)=>setShowP(e)} 
                                placeholder={"Contenue de l'article..."}
                                modules={modules}
                                formats={formats} 
                            />
                            {!showP ?<small className="text-danger" role="alert">contenu requis</small>:null}
                        </div>
                    </div>
                </form>

                <div className='text-center mt-2'>
                    {
                        read?<button disabled className='d-flex btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={{display : "flex", margin : "0 auto"}} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center ps-3'/>Patientez</button>:
                        error?<SubmitModal open={error} setOpen={setError} message={"Erreur survenu lors de la modification"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        ok?<SubmitModal open={ok} setOpen={setOk} message={"Article modifiée"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        <input type='submit' value='Modifier' onClick={updateActu} className='form-control bg-success border-success text-white fw-bold'/>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Show
