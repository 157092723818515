/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import logo from '../assets/img/LOGO EAR 2024-W.png'
import { ClipLoader } from 'react-spinners';
import SubmitModal from '../components/ui/SubmitModal';
import { AppContexte } from '../context/context';
import axios from 'axios';
import { URL_API_ACTU } from '../constant/url';


const New = () => {

    const {categorie, setActu, part, setPart, title, setTitle, subtitle, setSubtitle, text, setText, image, setImage } = useContext(AppContexte)

    const {register,formState: { errors },handleSubmit} = useForm();
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [error, setError] = useState(false)

    function preview (e){
        setImage(e.target.files[0])
    }


    const reading = {
        display : "flex",
        margin : "0 auto"
    }


    // Editor toolbor
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "code-block"
    ];

    const modules ={
        toolbar: [
            [{ 'font': [] }],
            [{size: []}],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            [{ list:  "ordered" }, { list:  "bullet" }, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'align': [] }],
            ["link"],
            ['clean']   
            ]
    }

    const submit = async() =>{
        if (text) {
            setRead(true)
            const formData = new FormData()

            formData.append('image', image)
            formData.append('title', title)
            formData.append('subtitle', subtitle)
            formData.append('text', text)

            await axios.post(`${URL_API_ACTU}${part}`, formData)
            .then(res =>{
                setRead(false)
                setOk(true)
                console.log(res.data);
                setActu(res.data)
                setImage()
                setText('')
                setTitle('')
                setSubtitle('')
                setPart('')
            }).catch((error) => {
                console.log(error.message)
                setRead(false)
                setError(true)
            })
        }
    }


    return (
        <div>
            <div className='text-center'>
                <h1 className='text-uppercase'>bienvenu sur l'interface d'ajout d'actu EAR </h1>
            </div>
            <div className='row justify-content-center align-items-start'>
                <div className='col-md-6 col-12'>
                    <div className='px-3 py-4 bg-white bg-opacity-50'>
                        <form>
                            <div className='row justify-content-start align-items-strech'>
                                <div className='col-12 mb-2'>
                                    <div className='inputBox w-100'>
                                        <select  {...register("part", {required: true})} onChange={(e)=>{setPart(e.target.value);}} value={part} className='w-100'>
                                            <option value={""} defaultValue> --Aucun choix--- </option>
                                            {
                                                categorie.map((elt,index)=>{
                                                    return(
                                                        <option value={elt.id} key={index} className='text-capitalize' > {elt.label} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <span>Sélectionner une catégorie</span>
                                        <i></i>
                                    </div>
                                    {errors.part && <small className="text-danger" role="alert">Catégorie requise</small>}
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='inputBox'>
                                        <input {...register("title", {required: true })} onChange={(e)=>(setTitle(e.target.value))} value={title} type='text' className='w-100'/>
                                        <span>Titre</span>
                                        <i></i>
                                    </div>
                                    {errors.title && <small className="text-danger" role="alert">Titre requis</small>}
                                </div>
                                <div className='col-12 mb-2'>
                                    <div className='inputBox'>
                                        <input {...register("subtitle", {required: false })} onChange={(e)=>(setSubtitle(e.target.value))} value={subtitle} type='text' className='w-100'/>
                                        <span>Sous titre</span>
                                        <i></i>
                                    </div>
                                    {/* {errors.subtitle && <small className="text-danger" role="alert">Sous titre requis</small>} */}
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='inputFile mb-2'>
                                        <label htmlFor='image' className='d-flex flex-column justify-content-center align-items-center h-100'>
                                            {!image?<i className="ri-folder-image-line ri-4x opacity-50"></i>:<img src={URL.createObjectURL(image)} className='img-fluid' />}
                                            <span className='text-opacity-50 text-black text-center fw-medium'>Cliquer pour choisir une image ( Entre 0 - 1Mo )</span>
                                        </label>
                                        <input {...register("image", {required: true })} id='image' onChange={preview} type='file' accept='image/*' max-file-size="1331200" className='w-100 d-none'/>
                                    </div>
                                    {errors.image && <small className="text-danger" role="alert">Image requise</small>}
                                </div>
                                <div className='col-12 mt-4'>
                                    <span className='ms-2'>Contenu</span>
                                    <ReactQuill 
                                        theme="snow" 
                                        value={text} 
                                        onChange={(e)=>setText(e)} 
                                        placeholder={"Contenue de l'article..."}
                                        modules={modules}
                                        formats={formats}
                                    />
                                    {!text ?<small className="text-danger" role="alert">contenu requis</small>:null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div className=''>
                        <h1 className='text-center'>Prévisualisation</h1>
                        <div className='bg-black container py-3 text-white'>
                            <h2 className='text-uppercase text-center'> {title} </h2>
                            <small className='text-ear'> {part} </small>
                            <img src={image?(URL.createObjectURL(image)):logo} className='img-fluid' />
                            <h5 className='text-ear text-start mt-3'> {subtitle} </h5>
                            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: text}}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center mt-4 col-12 mb-3'>
                {
                    read?<button disabled className='d-flex btn bg-transparent border border-2 border-warning'><ClipLoader 
                    color='#fbb03b' 
                    size={30} 
                    cssOverride={reading} 
                    aria-label='Loading spinner' 
                    data-testid='loader' 
                    className='text-center'/>Patientez</button>:
                    error?<SubmitModal open={error} setOpen={setError} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                    ok?<SubmitModal open={ok} setOpen={setOk} message={"Article publiée"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                    <button onClick={handleSubmit(submit)} type='submit' className='form-control w-100 me-3'>Poster</button>
                }
                <button type='reset' onClick={()=>{setPart(''); setImage(); setSubtitle(''); setTitle(''); setText('')}} className='form-control w-100 me-3'>Réinitialiser</button>
            </div>
        </div>
    )
}

export default New