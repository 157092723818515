import React from 'react'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'

const SubmitModal = (props) => {
    return (
        <Modal open={props.open} onClose={()=>{props.setOpen(false)}} animationDuration={3000} onAnimationEnd={()=>{props.setOpen(false)}} center={true} showCloseIcon={false}>
            <h2 className={'text-center ' + props.colorClass}><i className={props.class + " fs-0"}></i></h2>
            <p>{props.message}</p>
        </Modal>
    )
}

export default SubmitModal
