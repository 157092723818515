import React, { useContext, useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import SubmitModal from '../components/ui/SubmitModal'
import { AppContexte } from '../context/context'
import axios from 'axios'
import { URL_API_USER } from '../constant/url'

const Account = () => {


    const {user, setUser} = useContext(AppContexte)

    const [read, setRead] = useState(false)
    const [error, setError] = useState(false)
    // const [ok, setOk] = useState(false)

    const [show, setShow] = useState(false)
    const [authentif, setAuthentif] = useState('')
    const [modN, setModN] = useState(true)
    const [modP, setModP] = useState(true)
    const [name, setName] = useState(user[0].name)
    const [pass, setPass] = useState(user[0].pwd)


    useEffect(()=>{
        authentif === user[0].pwd ? setShow(true): setShow(false)
    },[authentif, user])



    function updateLog(e) {
        e.preventDefault()
        if (name === '') {
            setError(true)
        } else {
            const data = { name: name }
            setRead(true)
            axios.put(`${URL_API_USER}${user[0].id}`, data)
                .then(res => {
                    setRead(false)
                    // setOk(true)
                    axios.get(URL_API_USER).then((response) => { setName(response.data[0].name); setUser(response.data) })
                    setName(user[0].name)
                    setModN(true)
                }).catch((error) => {
                    console.log(error.message)
                    setRead(false)
                    setError(true)
                })
        }
    }

    const updatePass = (e) =>{
        e.preventDefault();
        if(pass === ''){
            setError(true)
        }else{
            const data = {pwd: pass}
            setRead(true)
            axios.put(`${URL_API_USER}${user[0].id}`,data)
            .then(res => {
                setRead(false)
                // setOk(true)
                axios.get(URL_API_USER).then((response)=>{setPass(response.data[0].pwd);setUser(response.data)})
                
                setModP(true)
            }).catch((error) => {
                console.log(error.message)
                setRead(false)
                setError(true)
            })
        }
    }


    const reading = {
        display : "flex",
        margin : "0 auto"
    }


    return (
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center account-page'>
                <div className='bg-white p-5 w-100 text-center'>
                    {
                        show ?
                        <>
                            <h1 className='text-uppercase'>Informations de votre compte</h1>
                            <table className='mx-auto'>
                                <tr>
                                    <td className='pt-3 pe-2 text-end'>Nom</td>
                                    <td className='pt-3 d-flex'>
                                        <input type={'text'} name='login' onChange={(e)=>setName(e.target.value)} value={name} className='form-control' disabled={modN}/>
                                        {
                                            modN? <button onClick={()=>setModN(false)} className='btn mx-1 my-1 bg-info'><i className='fa fa-pen-to-square'></i></button>:
                                            (
                                                read?<button disabled className='d-flex btn bg-transparent border border-2 border-warning'><ClipLoader 
                                                color='#fbb03b' 
                                                size={30} 
                                                cssOverride={reading} 
                                                aria-label='Loading spinner' 
                                                data-testid='loader' 
                                                className='text-center ps-3'/></button>:
                                                error?<SubmitModal open={error} setOpen={setError} message={"Erreur survenu lors de la modification"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                                                <button onClick={updateLog} className='btn mx-1 my-1 bg-primary'><i className='fa-regular fa-floppy-disk'></i></button>
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pt-3 pe-2 text-end'>Mot de passe</td>
                                    <td className='pt-3 d-flex'>
                                        <input type={'text'} name='pass' onChange={(e)=>setPass(e.target.value)} value={pass} className='form-control' disabled={modP}/>
                                        {
                                            modP? <button onClick={()=>setModP(false)} className='btn mx-1 my-1 bg-info'><i className='fa fa-pen-to-square'></i></button>:
                                            (
                                                read?<button disabled className='d-flex btn bg-transparent border border-2 border-warning'><ClipLoader 
                                                color='#fbb03b' 
                                                size={30} 
                                                cssOverride={reading} 
                                                aria-label='Loading spinner' 
                                                data-testid='loader' 
                                                className='text-center ps-3'/></button>:
                                                error?<SubmitModal open={error} setOpen={setError} message={"Erreur survenu lors de la modification"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                                                <button onClick={updatePass} className='btn mx-1 my-1 bg-primary'><i className='fa-regular fa-floppy-disk'></i></button>
                                            )
                                        }
                                    </td>
                                </tr>
                            </table>
                        </>
                        :
                        <div className='text-center'>
                            <span>Entrez votre mot de passe pour voir et modifier vos informations</span>
                            <div className='d-flex justify-content-center mt-4'>
                                <input type={'text'} name='authentif' placeholder='Mot de passe' onChange={(e)=>setAuthentif(e.target.value)} value={authentif} className='form-control w-auto'/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Account