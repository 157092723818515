import React, { useContext, useState } from 'react'
import brand from '../assets/img/LOGO EAR 2024-B.png'
import profil from '../assets/img/bg-connexion.webp'
import { AppContexte } from '../context/context'
import Cookies from 'js-cookie'
import axios from 'axios'
import { URL_API_USER } from '../constant/url'
import Modal from 'react-responsive-modal'
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    
    const {user, setUser, setLog} = useContext(AppContexte)
    const[userName, setUserName] = useState('')
    const[mdp, setMdp] = useState('')
    const[errT, setErrT] = useState('')
    const [err, setErr] = useState(false)
    const [update, setUpdate] = useState(false)
    
    const navigate = useNavigate()

    // constante de vérifiaction d'ouverture et de fermeture de la modal
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [error, setError] = useState(false)



    const handleLog = (e) =>{
        e.preventDefault()
        if( userName === user[0].name && mdp === user[0].pwd){
            setErr(false)
            Cookies.set('log',true, { expires: 0.5 })
            navigate("/")
            setLog(true)
        }else if (userName !== user[0].name ){
            setErr(true)
            setErrT("Nom d'utilisateur incorrect")
        }else if(mdp !== user[0].pwd){
            setErr(true)
            setErrT("Mot de passe incorrect")
        }
    }



    //Générer un mot de passe aléatoire
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@#$";
    const string_length = 8;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
    }

    
    const sendEmail = async(e) => {
        e.preventDefault()
        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                From : 'pangoafricadev@gmail.com',
                To : 'pangoafricadev@gmail.com',
                Subject : "Réinitialisation du mot de passe",
                Body : `<div>
                            <h1><strong>Nouveau mot de passe</strong></h1>
                            <p>Le mot de passe du site web admin de Eventify a été réinitialiser !</p><br/><br/>
                            <p>Le nouveau mot de passe est donc: ${randomstring} </p><br/><br/>
                            <p>Vous pouvez vous connecter avec ce mot de passe et le changer après votre connexion</p>
                        </div>`
            }).then((message) =>{
                axios.put(`${URL_API_USER}${user[0].id}`,{pwd: randomstring})
                .then(()=>{
                    axios.get(URL_API_USER).then((response)=>setUser(response.data))
                })
                .catch(() => {setError(true)})
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK'? setOk(true): setErr(true)
                )
            })
        }
    }



    return (
        <div className='login-bg'>
            <div className='container'>
                <div className='login d-flex justify-content-center align-items-center'>
                    <div className='row bg-white justify-content-center align-items-center'>
                        <div className='col-lg-8 px-0 col-12 position-relative'>
                            <img alt='connexion à EAR actu' src={profil} className='img-fluid login-img' />
                            <div className=' position-absolute bg-black bg-opacity-50 top-0 end-0 bottom-0 start-0 d-flex justify-content-center align-items-center flex-column text-center text-white'>
                                <h3>BIENVENUE SUR EAR ACTUALITES</h3>
                                <small>Connectez-vous pour éditer des actualités sur EAR</small>
                                <small className='position-absolute bottom-0 pb-2'>Visitez le site ear-gt.com</small>
                            </div>
                        </div>
                        <div className='col-lg-4 px-0 col-12'>
                            <div className='px-3 py-2'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img alt='connexion à EAR actu' src={brand} className='img-fluid login-brand' />
                                    <span className='text-uppercase'>Actualités <strong>EAR</strong></span>
                                </div>
                                <h3 className='fw-bolder text-center text-danger mt-3'>CONNEXION</h3>
                                <div className='mt-5'>
                                    <form>
                                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black'>
                                            <label htmlFor='login'  className='px-2 py-1 bg-danger d-flex justify-content-center align-items-center ms-2'>
                                                <i className='ri-user-3-line text-black'></i>
                                            </label>
                                            <input type='text' value={userName} onChange={(e)=>setUserName(e.target.value)} id='login' placeholder="Nom d'utisateur" className='form-control border-0'/>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center pb-1 border-bottom border-1 border-black mt-3'>
                                            <label htmlFor='mdp'  className='px-2 py-1 bg-danger d-flex justify-content-center align-items-center ms-2'>
                                                <i className='ri-lock-2-line text-black'></i>
                                            </label>
                                            <input type='password' value={mdp} onChange={(e)=>setMdp(e.target.value)} id='mdp' placeholder="Mot de passe" className='form-control border-0'/>
                                        </div>
                                        <div className='text-end mt-1 mb-2' role='button' onClick={()=>setUpdate(true)}>
                                            <small className='fst-italic text-danger'>Mot de passe oublié ?</small>
                                        </div>
                                        {
                                            err? <span className='text-danger'> {errT} </span> : null
                                        }
                                        <input type='submit' value={'Se connecter'} onClick={handleLog} className=' mt-3 py-2 border-0 bg-danger w-100 text-white fw-bold'/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal open={update} onClose={()=>{setUpdate(false)}} center={true} showCloseIcon={true}>
                <form className='p-3 px-4 text-center'>
                    <p> Appuyer sur<span className='span-link'> Réinitialiser le mot de passe </span> <br/> pour recevoir un nouveau mot de passe par E-mail </p>
                    {
                        read?<ClipLoader
                        color={'#ff4a19'}
                        size={30}
                        cssOverride={{display: "flex", margin: "0 auto"}}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className='text-center'
                        />:ok?
                        <Modal open={ok} onClose={()=>{setOk(false)}} showCloseIcon={true} center={true}>
                            <h2 className={'text-center text-success'}><i className={"ri-checkbox-circle-fill fs-0"}></i></h2>
                            <p className='text-center'>
                                Mot de passe réinitialiser ! Veuillez consulter votre boite mail.
                            </p>
                        </Modal>:
                        error?
                        <Modal open={error} onClose={()=>{setError(false)}} showCloseIcon={true} center={true}>
                            <h2 className={'text-center text-danger'}><i className={"ri-close-circle-fill fs-0"}></i></h2>
                            <p className='text-center'>
                                Une erreur c'est produite lors de l'envoie. Veuillez reéssayer !
                            </p>
                        </Modal>:
                        <input type="submit" onClick={sendEmail} className="bg-success border-0 px-4 py-2 text-white rounded-3" value={'Réinitialiser le mot de passe'}/>
                    }
                </form>
            </Modal>
        </div>
    )
}

export default Login