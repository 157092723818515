import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/vendor/fontawesome/css/all.min.css'
import './assets/vendor/remixicon/remixicon.css'
import './components/style/style.css'
import Sidebar from './components/ui/Sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import New from './views/New';
import Show from './views/Show';
import Account from './views/Account';
import { useContext, useEffect, useState } from 'react';
import { AppContexte } from './context/context';
import SearchBar from './components/ui/SearchBar';
import Loading from './views/Loading';
import Reload from './views/Reload';
import Login from './views/Login';

const App = () => {

  const {section, load, loadingErr, log} = useContext(AppContexte)

  const [dat,setDate] = useState(new Date())

    useEffect(()=>{
        setInterval(
            ()=>setDate(new Date())
            ,1000
        )
    })
    
  return (
    <>
      <BrowserRouter>
        {
          !load?(
            <Loading/>
          ):loadingErr?(
            <Reload/>
          ):
          !log?(
            <Login/>
          ):
          <div className='position-relative d-flex flex-row m-0 p-0 justify-content-start'>
            <div className='position-relative d-flex flex-row m-0 p-0'>
              <Sidebar/>
            </div>
            <div className='main'>

              <div className='main-head bg-warning px-3 py-md-3'>
                <button className="btn border-0 d-md-none p-0" data-bs-toggle="offcanvas" data-bs-target="#submenu">
                  <i className="ri-menu-line ri-2x"></i>
                </button>
                <div>
                  <span className='fw-bold text-uppercase text-black text-opacity-50'>
                    {section}
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <p className='d-md-block d-none text-black fw-bold text-opacity-50 m-0 '>{dat.toLocaleTimeString()}</p>
                  <SearchBar/>
                </div>
              </div>

              <div className='main-body px-2'>


                {/* Route */}
                <Routes>
                  <Route path='/'>
                    <Route index element= {(<Home/>) } />
                    <Route path='/add' element= {(<New/>) } />
                    <Route path='/show' element= {(<Show/>) } />
                    <Route path='/account' element= {(<Account/>) } />
                  </Route>
                </Routes>
                {/* End Routes */}


              </div>

            </div>
          </div>
        }
      </BrowserRouter>
    </>
  );
}

export default App;
