import React, { useContext } from 'react'
import { AppContexte } from '../context/context'
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register( ArcElement, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, );



const Home = () => {

    const {categorie, actu } = useContext(AppContexte)
    
    const num0 = actu.filter((word) => word.categorie_id === categorie[0].id).length;
    const num1 = actu.filter((word) => word.categorie_id === categorie[1].id).length;
    const num2 = actu.filter((word) => word.categorie_id === categorie[2].id).length;
    const num3 = actu.filter((word) => word.categorie_id === categorie[3].id).length;


    const stat = [
        {color: "text-info", icon: "fa fa-briefcase", number: num0, label: categorie[0].label},
        {color: "text-success", icon: "fa fa-medal", number: num1, label: categorie[1].label},
        {color: "text-secondary", icon: "fa fa-compass", number: num3, label: categorie[3].label},
        {color: "text-warning", icon: "fa fa-calendar-day", number: num2, label: categorie[2].label},
    ]



    const stateDoughnut = {
        labels: [categorie[0].label, categorie[1].label, categorie[2].label, categorie[3].label],
        datasets: [
            {
                label: 'Nombre',
                backgroundColor: [
                    'rgba(13,202,240)',
                    'rgba(25,135,84)',
                    'rgba(108,117,125)',
                    'rgba(255,193,7)',
                ],
                hoverBackgroundColor: [
                    'rgba(13,202,240,.7)',
                    'rgba(25,135,84,.7)',
                    'rgba(108,117,125,.7)',
                    'rgba(255,193,7,.7)',
                ],
                data: [num0, num1, num2, num3]
            }
        ]
    }



    return (
        <div className='px-md-4 px-2'>
            <div id='header-cards'>
                <div className='row'>
                    {
                        stat.map((cat, index) =>{
                            return(
                                <div key={index} className="col-xl-3 col-sm-6 col-12 mb-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div className="align-self-center">
                                                    <i className={cat.icon + " warning fa-3x float-left " +cat.color}></i>
                                                </div>
                                                <div className="media-body text-end">
                                                    <h3 className={cat.color}>{cat.number}</h3>
                                                    <span className='text-capitalize'> {cat.label} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div id='charts'>
                <div className='mt-5 row mx-auto justify-content-center'>
                    {/* <div className='col-lg-8 col-12 mb-5'>
                        <div>
                            <div className='chart-actu'>
                                <Line
                                    data={statePie}
                                    options={{
                                        title:{
                                            display:true,
                                            text:'Average Rainfall per month',
                                            fontSize:20
                                        },
                                        legend:{
                                            display:true,
                                            position:'right'
                                        }
                                    }}
                                    />
                            </div>
                        </div>
                    </div> */}
                    <div className='col-lg-3 col-12'>
                        <div>
                            <div className='chart-actu'>
                                <Doughnut
                                    data={stateDoughnut}
                                    options={{
                                        title:{
                                            display:true,
                                            text:"Nombre d'actus par catégories",
                                            fontSize:20
                                        },
                                        legend:{
                                        display:true,
                                        position:'right'
                                        }
                                    }}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
